/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.nav";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");
var events = require("../../../uam/events.js");
// var uae = require("./templates/ua.element.js");

var required = ["inner"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Navigation Bar",
  description: "A beutiful navigation bar for your app. If you use the fixed-top class, it will automatically add margin to the top of the body (this may overide other settings) so it doesn't overlap the content.",
  fields: {
    "inner": {
      type: "inner", 
      description: "The inner items of the navigation bar, these items will expand from the middle of the navbar."
    },
    "first": {
      type: "inner", 
      description: "The first items of the navigation bar, these items will exapnd from the left of the navbar.."
    },
    "last": {
      type: "inner", 
      description: "The last items of the navigation bar, these items will exapnd from the right of the navbar.."
    }
  },
  required: required
} 

/** 
 * If I have one fixed-top navbar, set the body margin-top to it's height so it doesn't overlap the content.
*/
events.on("interface.afterrender", async function() {

  var nav = document.querySelector(".navbar.fixed-top");
  if (nav) {

    //get the current padding top of the body
    // if (!document.body.style.paddingTop) {
    //   document.body.style.paddingTop = "0px";
    // }

    if (!(nav.hasAttribute("do-not-margin-body"))) {
      document.body.style.marginTop =  nav.offsetHeight + "px";
    }
 
  }

});

/**
 * Creates the navigation items from an array or object.
 * @param {*} items 
 * @returns the navigation items.
 */
function createNavItems(items) {

  if (!Array.isArray(items)) {
    return createNavItem(items);
  } 

  var navItems = [];
  for (var i = 0; i < items.length; i++) {
    navItems.push(createNavItem(items[i]));
  }

  return navItems;

}

/**
 * Create a navigation item for the navbar
 * @param {*} item The item to create the nav item for.
 * @returns JSON object of a nav item.
 */
function createNavItem(item) {
  return {
      n: "li",
      c: "nav-item",
      i: item
  }
}

/**
 * Creates a navigation group for the navbar (ul).
 * @param {*} item The item to create the nav item for.
 * @returns JSON object of a nav item.
 */
function createNavGroup(item) {
  return {
      n: "ul",
      c: "navbar-nav m-auto d-flex justify-content-between align-items-center text-center",
      i: item
  }
}

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // var options = json;

  var expandable = false;

  if ("expandable" in options) {
    expandable = options.expandable.trim().toLowerCase();
  }

  //create the navigation groups
  var inner = [];
  if ("first" in options) {
    var first = createNavItems(options.first);
    first = createNavGroup(first);

    if (expandable == "first") {
      first.c += " flex-fill justify-content-around";
    }

    inner.push(first);
  }

  if ("inner" in options) {
    var innerNav = createNavItems(options.inner);
    innerNav = createNavGroup(innerNav);

    if (expandable == "inner") {
      innerNav.c += " flex-fill justify-content-around";
    }

    inner.push(innerNav);
  }

  if ("last" in options) {
    var last = createNavItems(options.last);
    last = createNavGroup(last);

    if (expandable == "last") {
      last.c += " flex-fill justify-content-around";
    }

    inner.push(last);
  }

  //append the navivigation items
  var nav = {
    n: "nav",
    c: "navbar navbar-expand",
    "aria-label": "Navigation",
    i: {
      n: "div",
      c: "container-fluid d-flex",
      i: inner
    }
  };

  return await jsonRender.convertJSONToDOM(nav, {
    PassthroughOptions: options
  });

}

/** 
 * 
 * <nav id="navMain" class="navbar navbar-expand navbar-dark bg-blur-dark fixed-top" aria-label="Navigation">
        <div class="container">
<ul class="navbar-nav">
    <a class="navbar-brand mybrand d-inline-block" style="width:4rem; height:4rem;" href="/" title="home"></a>
</ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#home">Home</a>
                </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#developers">Developers</a>
              </li>
<li class="nav-item">
    
</li>
            </ul>
<ul class="navbar-nav">
    <li class="nav-item"><button class="btn btn-sm btn-info btn-hover-white text-uppercase ls-1" type="submit">Join the Beta</button></li>
</ul>
            
            
            
            
        </div>
      </nav>
*/
