/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.fs.sidebyside.sticky";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");

var required = ["top", "items", "side"];
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Fullscreen Sticky Side by Side",
  description: `A full screen creative element with a top layer on a side (left or right) and a list of full screen items on the other side. Scroll away!`,
  fields: {
    "top": {
      type: "inner",
      description: "The elements to render in the first layer of the screen."
    }, "side": {
      type: "list",
      list: ["left", "right"],
      description: "The side to render the top layer to, the second layer will be limited to the other side."
    }
  },
  required: required
}

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
  * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (json) {

  var options = uae.GetOptions(json, required);
  // console.log("Options:", options);
  /*
  <div class="vh-100 d-flex">
              <div class="m-auto">
                <h2 class="text-center" ua-observe>Real-time Technology.<br/>Curated for you.</h2>
              </div>
            </div> */

  //use the universe app contol.
  var $top = {
    n: "ua.fs.simple",
    i: options.top
  }


  // for reach option.items

  var items = [];

  if (Array.isArray(options.items)) {
    for (var i = 0; i < options.items.length; i++) {
      items.push({
        n: "ua.fs.simple",
        i: options.items[i]
      });
    }
  } else {
    items = {
      n: "ua.fs.simple",
      i: options.items
    }
  }

  // console.log("new", items);

  var $body = [];
  //add the other side to the top objects
  if (options.side === "left") {
    $top = [{
      n: "div",
      c: "col",
      i: $top
    }, {
      n: "div",
      c: "col",
      i: "&nbsp;"
    }];

    $body = [{
      n: "div",
      c: "col",
      i: "&nbsp;"
    }, {
      n: "div",
      c: "col",
      i: items
    }]
  } else {
    $top = [{
      n: "div",
      c: "col",
      i: "&nbsp;"
    }, {
      n: "div",
      c: "col",
      i: $top
    }];

    $body = [{
      n: "div",
      c: "col",
      i: items
    }, {
      n: "div",
      c: "col",
      i: "&nbsp;"
    }];

  }


  // var $background = false;

  // if ("background" in options) {
  //   if (options.background.type === "video") {
  //     $background = {
  //       n: "ua.fs.video",
  //       src: options.background.src
  //     }
  //   }
  // }

  //wrap it all in an article and add the sides.
  var article = {
    "n": "article",
    "class": "container-fluid",
    "inner": [{
        "n": "div",
        "class": "full-screen-sticky position-sticky",
        "inner": [//this is where to add the bacground
          {
            "n": "div",
            "class": "row",
            "inner": $top
          }
        ]
      }, {
        "n": "div",
        "class": "container-fluid",
        "inner": [
          {
            "n": "div",
            "class": "row",
            "inner": $body


          }
        ]
      }
    ]
  };
        
          // if ("background" in options) {
        //   if (options.background.type === "video") {
        //     article.inner[0].inner[0].inner.push({
        //       n: "ua.fs.video",
        //       src: options.background.src
        //     });
        //   }
        // }

        // console.log("About to build:", article);

        //render my json article
        return await jsonRender.render(article, {
          PassthroughOptions: options
        });
      }

/** 
<article class="container-fluid vh-100 d-flex">
<div class="row m-auto">
    <div class="col" data-ua-observe>
        <h1 class="text-center">Welcome to The Universe</h1>
    </div>
</div>
</article> */
