/**
 * For quick templating and conforming of Controls.
 * 
 * This is invalid
 */

var f = require("../../scripts/f.js");
var log = require("../../scripts/log.js");
var uaeAlert = require("../../elements/ua.e.alert.js");
var uae = require("../../elements/templates/ua.element.js");
var namespace = "ua.control";

var required = ["name", "title"];
exports.GetOptions = GetOptions;

/**
 *  Checks the control and gathers all the options of it.
 * If the contorl is set up through JSON, options will pass, if HTML
 * it will convert it to JSON.
 * Gather's all the options of the control, and compares to it's required outputs.
 * @param       {*} control       [description]
 * @param       {*} _required [description]
 * @constructor
 */
function GetOptions(options, _required = []) {
  var req = [];
  req = req.concat(_required, required);

  return uae.GetOptions(options, req);
}

exports.PrepareOutput = PrepareOutput;

/**
 *  Prepares the object to be outputed, also validates control.
 * @param       {*}  control                       [The control to validate.]
 * @param       {Boolean} [data=false]                  [The data to overide, if false, will use any avalable input output.]
 * @param       {Boolean} [_ShowValidationMessage=true] [description]
 * @constructor
 */
function PrepareOutput(control, data = false, _ShowValidationMessage = true) {
  var options = GetOptions(control, []);

  var inputs = control.querySelectorAll("input");
  var error = [];

  var newdata = [];
  var isvalid = true;

  if (data == false) {
    inputs.forEach((input, i) => {
      //check that the input is valid
      var valid = input.checkValidity();
      var message = "";

      if (valid !== true) {
        error.push (GetValidityMessage(input));
        isvalid = false;
      }

      newdata.push(input.value);

    });

    if (newdata.length == 1) {
      //remove array if not needed.
      newdata = newdata[0];
    }

    data = newdata;

  }

  //build the JSON data object based on the information
  var jsondata = {
    validation: {
      valid: isvalid,
      message: error
    },
    data: data,
    options: options,
    name: options.name
  }

  //show an alert with the validation message
  _ShowValidationMessageSnippet(control, jsondata, _ShowValidationMessage);

  return jsondata;

}

/**
 * Shows validation error message to the user based on the setting.
 * Or hides it.
 * 
 * @param {*} control 
 * @param {*} jsondata 
 * @param {*} _ShowValidationMessage 
 */
function _ShowValidationMessageSnippet(control, jsondata, _ShowValidationMessage = true) {
    //show an alert with the validation message
    if (_ShowValidationMessage) {
      if (!(isvalid)) {
        ShowValidationMessage(control, jsondata);
      } else {
        HideError(control);
      }
    }
}

/**
 * Encodes a validation error to submit from a UAC.prepare request.
 * @param {*} error 
 * @param {*} data 
 * @param {*} control 
 * @param {*} _ShowValidationMessage 
 */
function EncodeValidationError(error, data, control, _ShowValidationMessage = true) {
  var options = GetOptions(control, []);
  var jsondata = {
    validation: {
      valid: "false",
      message: error
    },
    data: data,
    options: options,
    name: options.name
  };

  _ShowValidationMessageSnippet(error, data, control, _ShowValidationMessage);
  return jsondata;

}

/**
 * Ensures a control, is outputed as a control.
 * @param {*} control Must be a dom object
 */
function Render_FinalStep(control) {
  if (control.tagName == "CONTROL") {
    return control;
  } else {
    var parent = document.createElement("control");
    parent.appendChild(control);
    return parent;
  }
}

exports.Render_FinalStep = Render_FinalStep;

exports.StoreValue = StoreValue;
/**
 * Stores the JSON object into a JSON element.
 * Will overide any JSON element present.
 * @param       {*} control  [The Control to use.]
 * @param       {*} jsondata [The data to output.]
 * @constructor
 */
function StoreValue(control, jsondata) {
  var json = control.querySelector("json");
  if (json !== null) {
    json.innerText = JSON.stringify(jsondata);
  } else {
    json = document.createElement("json");
    json.innerText = JSON.stringify(jsondata);
    control.appendChild(json);
  }
}

exports.ShowValidationMessage = ShowValidationMessage;
function ShowValidationMessage(control, json) {
  var error = json.validation.message;
  if (Array.isArray(error)) {
    var nError = "";

    error.forEach((item, i) => {
      nError = `<p>${item}</p>`;
    });

    error = nError;
  }

  ShowError(control, error);
}


exports.ShowError = ShowError;

/**
 * Show an alert of type "danger".
 * @param       {*} control           [the element to append or prepend the message]
 * @param       {*} error             [the error HTML to out put]
 * @param       {String}  [errorIconClass="!default"] [description]
 * @param       {Boolean} [prepend=false]             [description]
 * @constructor
 */
function ShowError(control, error, errorIconClass = "!default", prepend = false) {

  var ele = control.querySelector("element[uai='form-validation-message']");
  if (ele == null) {

    var ele = document.createElement("element");
    ele.setAttribute("namespace", "ua.e.alert");
    ele.setAttribute("alertclass", "alert-danger");
    ele.setAttribute("uai", "form-validation-message");
    ele.setAttribute("icon", errorIconClass);
    ele.innerHTML = error;

    uaeAlert.render(ele);
    var fAlert = ele.querySelector("element[namespace='" + uaeAlert.namespace + "']");
    if (!(fAlert == null)) {
      fAlert = ele;
    } else {
      if (prepend) {
        contorl.prependChild(ele);
      } else {
        control.appendChild(ele);
      }
    }

  } else {
    uaeAlert.update(ele, error);
  }

}

exports.HideError = HideError;
function HideError(control) {
  var error = control.querySelector("element[uai='form-validation-message']");
  if (!(error == null)) {
    error.remove();
  }
}

function GetValidityMessage(input) {
  return input.validationMessage;
}

exports.PassOptions = f.PassOptions;

/**
 * Passes the prefilled help properties to the control.
 */
exports.helpTemplate = {
  type: "control"
}

exports.help = {
  title: "Control",
  description: "A control that can be used to validate and output data.",
  namespace: namespace,
  properties: {
    "name": {
      description: "The name of the data that will be submitted to the server. The ID is clientside only.",
      required: true,
      default: false
    },
    "title": {
      description: "The user friendly title of the data you are submitting. This is used for accessibility and display to the user.",
      required: true,
      default: false
    },
    ...uae.help.properties
  },
  type: "element",
  example: {
    html: `<control name="mydata" title="My Data"></control>`,
    json: {
      name: "mydata",
      title: "My Data"
    }
  }
};

