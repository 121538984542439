/* {"ns":"universe.functions.resolve", "v":1, "i": false} */

// const { resolveInclude } = require("ejs");

/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "resolve";

// console.log("Resolving path", p);
var path = require("path");

var santize = require("../sanitize.js");

/**
 * Resloves a path based on the APP as the home directory. Uses path.join on additional arguments.
 * @! => process.cwd()
 * 
 * @!/functions will equal /myapp path.join with "./functions"
 * /myapp/functions
 * @param {*} path The path to resolve.
 * @returns an adjusted path
 */
function resolve(...p) {

    try {
        if ("~" == p[0].substring(0,1)) {
            throw new Error(`Resolving a path with a "~" is no longer possible. Use "@!" instead.`);
        }
      
    } catch (error) {
        console.log("Error", error);
        console.log("Path", {p: p});
    }

    //if the path is just one string, then use that.
    if (p.length == 1) {
        p = p[0];
    } else {
        p = path.join(...p);
    }

    

    if (!santize("string", p)) throw new Error(`Path must be a string. (${p})`);

    var rtn = "";

    //I'm requesting to be resolved to app root
    if (p.substring(0, 2) == "@!") {
        rtn = path.join(process.cwd(), `.${p.substring(2)}`);

        //if the rtn starts with a ../
        if (rtn.substring(0, 3) == "../") {
            console.warn(`The path object returned an invalid path. (${rtn})`);
        }

    } else {
        rtn = p;
    }

    return rtn;

}

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: resolve,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           
            //test resolve

            var p = "@!/functions";
            var result = resolve(p);

            console.log("Result", {
                result: result,
                cwd: process.cwd(),
                p: p
            });

            if (result == process.cwd() + "/functions") {
                return true;
            } else {
                return false;
            }

        }
    }]
};