
var namespace = "ua.scroll.totop";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js"); 

exports.define = {  
  namespace: namespace,
  title: "UA Scroll to Top",
  description: "Adds a button to the bottom right of the page that scrolls to the top of the page.",
  fields: false,
  required: false//,
  // supportClient: false,
  // supportServer: true
}

var ScrollToBoundry = 1000;


var uat_btn_top = document.createElement("a");
uat_btn_top.classList.add("btn")
uat_btn_top.classList.add("btn-text-white")
uat_btn_top.classList.add("text-shadow");
uat_btn_top.classList.add("box-shadow");
uat_btn_top.classList.add("tiny-outline");
uat_btn_top.classList.add("bg-75-black");
uat_btn_top.style.position = "fixed";
uat_btn_top.style.bottom = "5px";
uat_btn_top.style.right = "5px";
uat_btn_top.style.zIndex = "100000";
uat_btn_top.style.opacity = "0";
uat_btn_top.style.display = "none";
uat_btn_top.innerHTML = "<i class='bi bi-arrow-up-circle-fill'></i>";
uat_btn_top.onclick = function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

document.body.appendChild(uat_btn_top);

window.onscroll = function() {scrollFunction()};
function scrollFunction() {
    if (document.body.scrollTop > ScrollToBoundry || document.documentElement.scrollTop > ScrollToBoundry) {
        uat_btn_top.classList.add("d-block");
        uat_btn_top.classList.remove("d-none");

        if (uat_btn_top.style.opacity == "0") {
            uat_btn_top.style.opacity = "1";
        }

    } else {
        uat_btn_top.classList.remove("d-block");
        uat_btn_top.classList.add("d-none");

        if (uat_btn_top.style.opacity == "1") {
            uat_btn_top.style.opacity = "0";
        }
    }
  }

  exports.render = async function (options) {
    throw new Error("The UA Scroll to Top element does not get rendered, it automatically adds itself to all pages.");
  }