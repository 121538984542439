/**
 * Universe App Tools
 * Displays the actual list of footnotes as items in an ordered list.
 * 
 * Created by Justin K Kazmierczak.
 */
var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");

var footnotes = [];

var required = ["inner"];
exports.required = required;

var namespace = "ua.footnotes";
exports.namespace = namespace;

exports.define = {
  namespace: namespace,
  supportServer: false,
  fields: {
    "inner": {
      type: "inner", 
      description: "Additional items to add to the footer."
    }
  },
  required: required,
  title: "Footnotes",
  description: `Keeps track of your footnotes added to a document. Creating this object at the end of the document will output a numeric list of footnotes.`,
  limit: 1, //automatically show an error if more than one is requested during a render session
  closes: ["ua.footnote"] //automatically detect if this object is not created but it's children are. IE: You must close a list of footnotes (ua.footnote) with ua.footnotes.
} 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele 
  * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (ele) {

  if (!(myFooter === null)) {
    // console.log("A footer already exists. Please only use one footer per page.");
    return undefined; //returned on an error.
  }


  var options = uae.GetOptions(ele, required);

    var items = [];

    if (footnotes.length === 0) {
      
      // items.push({
      //   n: "li",
      //   c: "text-center",
      //   i: "No footnotes were added to this document."
      // })

    } else {
    //for reach footnote add a list item
    for (var i = 0; i < footnotes.length; i++) {
      items.push({
        n: "li",
        id: `footnote-${i+1}`,
        i: footnotes[i]
      })
    }
  }

    var article = {
      n: "article",
      i: [{
        n: "ol",
        c: "text-start",
        i: items
      }]
    }
    

    if (options.inner) {

      var ul = {
        n: "ul",
        c: "text-start",
        i: []
      };

      // if it's an array or one item, add it to a non-ordered list
      if (Array.isArray(options.inner)) {
        for (var i = 0; i < options.inner.length; i++) {
          ul.i.push({
            n: "li",
            i: options.inner[i]
          });
        }
      } else {
        ul.i.push({
          n: "li",
          i: options.inner
        });
      }

      article.i.push(ul);
    }

    //if there is an inner, check for footnotes and add them
    return await jsonRender.render(article, {
      PassthroughOptions: options
    });


};

var myFooter = null;


/**
 * Adds a footnote to the page.
 * @param {*} footnote The footnote innerHTML to add to the footnote object.
 */
function AddFootnote(footnote) {
  return footnotes.push(footnote);
} exports.add = AddFootnote;


/**
 * Finds the footnote ID for the given footnote.
 * @param {*} _footnote The footnote object to compare (through stringify).
 * @returns The ID of the footnote, or -1 if it does not exist.
 */
function FindFootnoteId(_footnote) {
  var _footnoteTest = JSON.stringify(_footnote).trim().toLowerCase();
  
  for (var i = 0; i < footnotes.length; i++) {
    var footnoteTest = JSON.stringify(footnotes[i]).trim().toLowerCase();
    
    // console.log(`Testing footnote ${i}...`, {
    //   _footnoteTest: _footnoteTest,
    //   footnoteTest: footnoteTest
    // });

    if (footnoteTest == _footnoteTest) {
      // console.log(`Footnote already exists at ${i}.`);
      return i + 1;
      break;
    }
  }

  return -1;
} exports.FindFootnoteId = FindFootnoteId;

/**
 * Removes the element from the footnote list.
 * @param {*} footnote 
 */
function remove(footnote) {
  var index = footnotes.indexOf(footnote);
  if (index > -1) {
    footnotes.splice(index, 1);
  }
} exports.remove = remove;
