/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

var namespace = "ua.fs.simple";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
// var uae = require("./templates/ua.element.js");

var required = ["inner"];
exports.required = required;



exports.define = {
  namespace: namespace,
  fields: {
    "ua-observe": {
      type: "string", 
      description: "Activates the animation when the element is in view."
    }
  },
  required: required
} 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
  * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // var options = uae.GetOptions(json, required);
  // // return jsonRender.render({
  // //   n: "article",
  // //   c: "container-fluid vh-100 d-flex",
  // //   i: {
  // //     n: "div",
  // //     c: "row",
  // //     i: options.inner ?? ""
  // //   }
  // // })

  //   var article = document.createElement("article");
  //   article.classList.add("container-fluid");
  //   article.classList.add("vh-100");
  //   article.classList.add("d-flex");

  //   var row = document.createElement("div");
  //   row.classList.add("row");
  //   row.classList.add("m-auto");

  //   var col = document.createElement("div");
  //   col.classList.add("col");

  //   if ("ua-observe" in options) {
  //     col.setAttribute("ua-observe", "");
  //     delete json["ua-observe"];
  //   }

  //   if ("inner" in options) {
  //     $result = jsonRender.convertJSONToDOM(options.inner);
  //     // console.warn("Attempted adding of inside", $result);
  //     col.appendChild($result);
  //   }

  //   row.appendChild(col);
  //   article.appendChild(row);

    // uae.PassthroughOptions(article, options, ["inner"]);

    var article = {
      n: "article",
      c: "container-fluid d-flex min-vh-100 p-0 m-0",
      i: {
        n: "div",
        c: "row m-auto",
        i: {
          n: "div",
          c: "col",
          i: options.inner ?? ""
        }
      } 
    };

    if ("image" in options) {
      
      // article = {
      //   n: "div",
      //   c: "container-fluid vh-100 d-flex ua-fs-background-image-holster",
      //   i: [
      //     {
      //       n: "img",
      //       c: "ua-fs-background-image image-fixed",
      //       src: options.image
      //     },
      //     article
      //   ]
      // }
      
      article.style = "background-image: url('" + options.image + "');";
      article.c = article.c + " image-parallax";

    }

    if ("ua-observe" in options) {
      article.i.i["ua-observe"] = "";
    };
    
    return await jsonRender.render(article, {
      PassthroughOptions: options
    });

};

/** 
 * 
   <div class="container-fluid vh-100 d-flex ua-fs-background-image-holster">
            <img src="../../../../main-background.jpg" class="ua-fs-background-image image-fixed" style="z-index:-1000;">
            <div class="d-block">
              <article>...</article>
            </div>
  </div>
 * 
<article class="container-fluid vh-100 d-flex">
<div class="row m-auto">
    <div class="col" data-ua-observe>
        <h1 class="text-center">Welcome to The Universe</h1>
    </div>
</div>
</article> */
