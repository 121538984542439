/*** STOP ***

  This element is purely for editing and e-mail.

  If you are a developer, writing your pages (and not building them in database)
  try to use the HTML <p> tag and not an <element> tag.

  The element tag is designed for database stored tags, e-mail, and editing them.

*** STOP ***/

var f = require("../scripts/f.js");
var uae = require("./templates/ua.element.js");
var log = require('../scripts/log.js');
var required = ["src", "alt"];
var nopass = []; //the items that don't get passed to the object.

exports.namespace = "ua.e.image";
exports.render = function (ele) {
  var options = uae.GetOptions(ele, required);

  var img = document.createElement("img");
  img = uae.PassthroughOptions(img, options, nopass);

  //log.info("The image is passing...", img);
  return img;

};

/**
 * Report if the alert is rendered. (uae)
 * @param  {*} element [description]
 * @return {*}         [description]
 */
exports.isRendered = function (element) {
  return uae.isRendered(element);
}

/**
 * Creates the object that will be used to render the e-mail
 * @param  {*} $my   [The e-mail config]
 * @param  {*} brand [The brand colors and style]
 * @param  {*} element   [The element]
 * @return {*}       [description]
 */
exports.renderEmail = function($my, brand, element) {

  var options = uae.ValidateOptions(element, required);
  if (options.success == false) {
    return options;
  } else {
    options = options.options;
  }

  options = uae.FillPlaceHoldersEmails(options, $my, brand);

  log.info("Rendered Options", options);

  var tablerow = document.createElement("tr");
  tablerow.setAttribute("border", 0);
  tablerow.setAttribute("cellpadding", 0);
  tablerow.setAttribute("cellpadding", 0);

  var tablecell = document.createElement("td");
  tablecell.setAttribute("style", `${$my.style.defaultcell} ${$my.style.fonts} ${$my.style.fontsize} color: ${brand.textcolor};`);

  var img = document.createElement("img");
  img = uae.PassthroughOptions(img, options, nopass);

  img.setAttribute("border", "none");

  if ("height" in options) {
    img.height = options.height;
  }

  img.setAttribute("width", $my.style.maxwidth);

  img.setAttribute("style", `${$my.style.padding} margin:0px; ${$my.style.fonts} ${$my.style.fontsize} color: ${brand.textcolor};
    width: ${$my.style.maxwidth}; border: none 0px;`);

  //simg.setAttribute("src", = options.image;

  tablecell.appendChild(img);
  tablerow.appendChild(tablecell);

  return {
    success: true,
    orginal: uae,
    row: tablerow
  };

  // return {
  //   success: false,
  //   fault: "pending",
  //   error: "This part of the function is stll being written",
  //   original: uae
  // }

}
