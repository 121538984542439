/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

var namespace = "ua.card.simple";
exports.namespace = namespace;

var uae = require("./templates/ua.element.js");

var jsonRender = require("../interface/jsonRender.js");
var required = ["inner"];
exports.required = required;

exports.define = {
  namespace: namespace,
  fields: {
    "ua-observe": {
      type: "string", 
      description: "Activates the animation when the element is in view."
    }
  },
  required: required
} 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (json) {

  var options = uae.GetOptions(json, required);

// <div class="card m-auto">
//   <div class="card-body">
//       <h3>Your the secret...</h3>
//     <p class="text-justify">Bring your entrepreneurial mind, and craft your corner of the Universe. Build your website, develop your app, and grow your community.</p>
//   </div>
// </div>

  return await jsonRender.render({
    n: "div",
    c: "card m-auto",
    i: {
      n: "div",
      c: "card-body",
      i: options.inner
    }
  }, {
    PassthroughOptions: options
  });
  

};