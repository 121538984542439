/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * @jumpcutking/console
 * 
 * Justin K Kazmierczak
 * 
 * Parses a stacktrace into a standard format.
 * 
 */

var namespace = "parseStackTrace";

/**
 * Parses a stacktrace into a standard format.
 * Any unknown format will be added to the stacktrace as a string. 
 * This is helpful for Error: top line messages.
 * If an object is passed in, it will asume the stacktrace is already parsed and return it.
 * @param {*} stackTrace The error.stack string.
 * @param {*} removeLvl The number of lines (or calls) to remove from the stacktrace.
 * @returns {Array} An array of stack objects.
 * @property {Object} stack The stacktrace object.
 * @property {string} stack.call The function or object called.
 * @property {string} stack.file The file the message originated from.
 * @property {number} stack.line The line the message originated from.
 * @property {number} stack.column The column the message originated from.
 * @public
 */
function parseStackTrace(stackTrace, removeLvl = 0) {

    //if the stacktrace is an object return it
    if (typeof stackTrace === "object") {
        return stackTrace;
    }
    var lines = stackTrace.split('\n');

    //remove the lines from the top of the stacktrace
    if (removeLvl > 0) lines.splice(0, removeLvl);

    var newStack = [];

    var patternWithCall = /at (.*) (.+):(\d+):(\d+)/;
    var patternWithoutCall = /at (.+):(\d+):(\d+)/;
  
    for (var line of lines) {
        var match = line.trim();

        if (match == "") {
            continue;
        }

        match = match.match(patternWithCall);

        if (!match) {
        match = line.match(patternWithoutCall);
        }


        if (match) {
        
          var [, call, file, lineNum, columnNum] = match;

          //remove only frist instance of ( from file
          if (file[0] == "(") {
            // remove first character
            file = file.substring(1);
          }


          var newObj = {
            call: call || false, // Set call to empty string if it doesn't exist
            file: file,
            line: parseInt(lineNum, 10),
            column: parseInt(columnNum, 10),
            s: line.trim(),
          }

          if (columnNum == undefined) {
            newObj.call = null;
            newObj.file = call;
            newObj.line = parseInt(file, 10);
            newObj.column = parseInt(lineNum, 10);
          }

            newStack.push(newObj);

        } else {

            newStack.push(line);

        }
    
    }
  
    return newStack;
}

module.exports = {
    namespace: namespace,
    function: parseStackTrace,
    tests: [{
        namespace: `${namespace}.test`,
        invalidTest: true,
        must: true,
        run: async () => {
    
            var myStack = parseStackTrace(new Error().stack, 1);
            console.log("My stack", myStack);
            return true;

           }
    }] 
};