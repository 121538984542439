/**
 * Protected by The Universe Terms of Service and Privacy Policy.
 * Subject to the Terms of Service and Privacy Policy.
 * 
 * Justin K Kazmierczak
 * © 2023. The Universe & Justin K Kazmierczak. All rights reserved.
 * 
 * Copy and distribution of this code is strictly prohibited.
 */

var namespace = "ua.a.typeon";
exports.namespace = namespace;

var f = require("../scripts/f.js");
var log = require("../scripts/log.js");
var uae = require("../elements/templates/ua.element.js");

var required = ["ua-typeOn"];
exports.required = required;

var defualtBlinkingCursor = "_";
var typingDelay = 75; // Delay between typing each character
var waitDelay = 800; // Delay between erasing each character

exports.define = {
  namespace: namespace,
  fields: [{
    "data": {
      type: "array", 
      description: "The words or phrases to type on and off the screen"
    }
  }, {
    "blinkingcursor": {
        type: "string",
        description: "The blinking cursor to use.",
        default: "_"
    }
  }],
  required: required,
  supportServer: false,
} 

/**
 * Report if the alert is rendered. (uae)
 * @param  {*} element [description]
 * @return {*}         [description]
 */
exports.isRendered = function (element) {
  return uae.isRendered(element);
}

/**
 * Called by the interface to render the animation
 * @param {*} json The json to render the type on animation on.
 */
exports.render = function (json) {

    var options = uae.GetOptions(json, required);

    // console.log(`${namespace} rendering request`, {
    //     json: json,
    //     options: options
    // });

    // if (!(options.uarendered === true)) {

        // create span object
        var ele = document.createElement("span");

        // if "blankingcursor" is not set, use the defualt
        if (!("blinkingcursor" in options)) {
            options.blinkingcursor = defualtBlinkingCursor;
        }

        const attributeValue = options.data;
        //if attribute value is not an array, but a string convert it
        if (typeof attributeValue === "string") {
            options.data = JSON.parse(attributeValue);
        }

        const stringsArray = options.data;
        let currentIndex = 0; // Index of the current string in the array
        let currentString = stringsArray[currentIndex]; // Current string to display
        let currentText = ''; // Current text being typed

        const blinkingCursor = `<span class="blinking">${options.blinkingcursor}</span>`;

        // Function to animate typing effect
        function typeWriter() {
            if (currentText.length < currentString.length) {
                currentText += currentString.charAt(currentText.length);
                ele.innerHTML = currentText + blinkingCursor;
                setTimeout(typeWriter, typingDelay); // Delay between typing each character
            } else {
                setTimeout(eraseText, waitDelay); // Delay before erasing text
            }
        }

        // Function to erase typed text
        function eraseText() {
            if (currentText.length > 0) {
                currentText = currentText.slice(0, -1);
                ele.innerHTML = currentText + blinkingCursor;
                setTimeout(eraseText, typingDelay); // Delay between erasing each character
            } else {
                currentIndex = (currentIndex + 1) % stringsArray.length; // Move to the next string in the array
                currentString = stringsArray[currentIndex];
                setTimeout(typeWriter, typingDelay); // Delay before typing next string
            }
        }

        // Start the typing animation
        typeWriter();

    // }

    return ele;

};



// /** Get all span objects with the attribute "ua-anim-typeOn", convert that atrribute contents to
//  * an json array, and than animate it so each string in the array "types on the screen" like a typewriter,
//  * wait a moment, backspace all the letters, and advance to the next string in the array. At the end of the
//  * array return to the first string in the array and repeat the process.
//  * Example HTML:
//  *  <p class="text-center">Your <span ua-anim-typeOn='["shopping", "searching", "dreaming", "gaming", "streaming", "coding", "browsing", "sharing", "creating"]'>coding</span> experience just got better.</p>
//  */
// function TypeOn() {
//   // Get all span elements with the attribute "ua-anim-typeOn"
//   const spanElements = document.querySelectorAll('span[ua-typeOn]');
//   const blinkingCursor = `<span class="blinking">_</span>`;
//   // Iterate over each span element
//   spanElements.forEach((span) => {
//     const attributeValue = span.getAttribute('ua-anim-typeOn');
//     const stringsArray = JSON.parse(attributeValue);
//     const typingDelay = 75; // Delay between typing each character
//     const waitDelay = 800; // Delay between erasing each character

//     let currentIndex = 0; // Index of the current string in the array
//     let currentString = stringsArray[currentIndex]; // Current string to display
//     let currentText = ''; // Current text being typed

//     // Function to animate typing effect
//     function typeWriter() {
//       if (currentText.length < currentString.length) {
//         currentText += currentString.charAt(currentText.length);
//         span.innerHTML = currentText + blinkingCursor;
//         setTimeout(typeWriter, typingDelay); // Delay between typing each character
//       } else {
//         setTimeout(eraseText, waitDelay); // Delay before erasing text
//       }
//     }

//     // Function to erase typed text
//     function eraseText() {
//       if (currentText.length > 0) {
//         currentText = currentText.slice(0, -1);
//         span.innerHTML = currentText + blinkingCursor;
//         setTimeout(eraseText, typingDelay); // Delay between erasing each character
//       } else {
//         currentIndex = (currentIndex + 1) % stringsArray.length; // Move to the next string in the array
//         currentString = stringsArray[currentIndex];
//         setTimeout(typeWriter, typingDelay); // Delay before typing next string
//       }
//     }

//     // Start the typing animation
//     typeWriter();
//   });
// }

    
//   // Call the TypeOn function to initiate the animation
//   TypeOn();