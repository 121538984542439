/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "row";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");

var required = [];
exports.required = required;

exports.define = {
  namespace: namespace,
  fields: {
    "top": {
      type: "inner", 
      description: "The elements to render in the first layer of the screen."
    }
  },
  required: required
} 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (json) {

  var options = uae.GetOptions(json, required);
  var inner = options.inner ?? "";
  return await jsonRender.convertJSONToDOM({
    n: "div",
    c: "row",
    i: inner
  }, {
    PassthroughOptions: options
  });

}

/** 
<article class="container-fluid vh-100 d-flex">
<div class="row m-auto">
    <div class="col" data-ua-observe>
        <h1 class="text-center">Welcome to The Universe</h1>
    </div>
</div>
</article> */
