/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Recursively converts short hand object name to complete object name.
 * 
 */

var namespace = "getProperty";

/**
 * Recursively searches for a property in an object by it's path.
 * @param {String} path The path to the property.
 * @param {Object} obj The object to search.
 *  @returns {Object} the object with the shorthand converted to full name.
 */
function getProperty(p, obj) {
    // console.log("getProperty", {
    //     p: p,
    //     obj: obj,
    //     typeOfP: typeof p
    // });
    const parts = p.split('.');
    const currentPart = parts[0];
  
    if (parts.length === 1) {
      return obj[currentPart];
    }
  
    if (obj.hasOwnProperty(currentPart)) {
      return getProperty(parts.slice(1).join('.'), obj[currentPart]);
    }
  
    return undefined;
}


module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: getProperty,
    tests: [{
        namespace: `${namespace}.defualt`,
        must: true,
        run: async () => {

            var obj = {
                x: {
                    y: {
                        z: true
                    }
                }
            };

            return getProperty("x.y.z", obj);

        }
    }, {
        namespace: `${namespace}.undefined`,
        must: true,
        run: async () => {
                
            var obj = {
                x: {
                    y: {
                        z: true
                    }
                }
            };

            var rtn = getProperty("x.y.a", obj);

            // if rtn is undefined
            return rtn === undefined;
    
        }
    }] 
};