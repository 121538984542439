/* {"ns":"universe.module.registry", "v":2, "i":false} */
/**
 * This Factory will create a registry to be used internally by the bject that is utilizing it.
 * 
 * The factory uses namespaces to prevent the same object from being added to the registry.
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * all components that use modularity
 * 
 * Justin K Kazmierczak
 * All Rights Reserved.
 * 
 * May be subject to The Universe Terms of Service.
 **/
//My personal log.
var log = require("./logger")();

// const { kStringMaxLength } = require("buffer");
// const { findSourceMap } = require("module");
// const { level_info } = require("./logger");
var santize = require("./sanitize.js"); 

var ParseStackTrace = require("./functions/parseStackTrace.js").function;
var GenerateStacktrace = require("./functions/generateStacktrace.js").function;

 /**
  * Creates a registry that has events (registry.events) it may call.
  * It will call validation before adding (and checking namespace);
  * It will call onAdd after adding a new item.
  * @returns A registry.
  */
function create(namespace = "registry") {

  return {
      ParseStackTrace: ParseStackTrace,
      GenerateStacktrace: GenerateStacktrace,
      created: Date.now(),
      namespace: namespace,
      registry: [],
      events: {
        validation: [],
        onAdd: [],
        search: []
      },
      search: search,
      find: search,
      add: add,
      addFromDir: addFromDir,
      addEvent: addEvent,
      listRegistered: listRegistered,
      list: listRegistered,
      length: length
  };
} module.exports.create = create;

/**
 * Reports the length of the registry.
 * @returns {number} The length of the registry.
 */
function length() {
  return this.registry.length;
}

/**
 * Will safely push a delegate.
 * @param {string} type 
 * @param {function} delegate 
 */
function addEvent(type, event) {
  log.add("Module: Registry.addevent", "New Event:", {
    type: type,
    event: event
  }, i);
  
  santize.log(log, "function", event);

  if (type === "validate") {
    this.events.validation.push(event);
  } else if (type === "onAdd") {
    this.events.onAdd.push(event);
  }

}

/**
 * Lists all the registered namespaces (easier to use in fast loops and indexing).
 * @returns {Array} An array of namespaces.
 */
function listRegistered() {
  var arr = [];
  for (var i = 0; i < this.registry.length; i++) {
    arr.push(this.registry[i].namespace);
  }
  return arr;
}

/**
 * Searches for an item in the current registry by it's namespace.
 * @param {String} namespace 
 * @returns the item or false
 */
function search(namespace, reportToLog = false) {
  var found = false;

  santize.log(log, "string", namespace);

  namespace = NormalizeNamespace(namespace);

  //search for namespace
  for (var i = 0; i < this.registry.length; i++) {
    if (this.registry[i].namespace == namespace)  {
      return this.registry[i];
    }
  }

  if (!found) {
    if (reportToLog) {
      log.add("search",  `Could not find namespace: ${namespace}.`, 3, this.namesapce);
      // log.add("Module: Registry.search.namespace", `Could not find namespace: ${namespace}.`, this.namesapce, 3);
      //log.e(_proc, _st, "NamespaceNotFound", `Could not find namespace: ${namespace}.`, namespace);
    }
    
    return false;
    //throw `Could not find namespace: ${namespace}.`;
  }
}

/**
 * Normalizes a namespace to be all lowercase and trims all spaces.
 * @param {*} namespace The namespace to normalize.
 * @returns {string} The normalized namespace.
 */
function NormalizeNamespace(namespace) {
  return namespace.trim().toLowerCase();
} module.exports.NormalizeNamespace = NormalizeNamespace;

/**
 * Adds an item with a namespace to the registry.
 * - checks if the item has a namespace, if not, it checks the defination
 *   for a namespace item.define.namespace.
 * - uses validation functions on registry.events.validation (self, item);
 * - uses addition (after completion) function on registry.events.add (self, item)
 * - All namespaces are normalized using the normalize namespace function during
 *   search or add.
 * @param {*} item The module or object to add. 
 * @param {*} from The location of the item that was added (how it was added). If false, will autogenerate. Defaults to false.
 * @returns {boolean} If the item was added successfully.
 */
function add(item, from = false) {

  santize.log(log, "object", item);

  if (this.events.validation.length > 0) {
    // log.i(this._proc, this._st, "Validation-start", "Attempting Validation", this.events.validation);
    for (var i = 0; i < this.events.validation.length; i++) {
      var event = this.events.validation[i];
      var repo = event(this, item);
      if (!repo.success) {
        log.add("Module: Registry.add.validation", "The object failed validation.", {
          repo: repo,
          event: event,
          item: item
        }, 3);
        return false;
      }
    }
  }

  if (!("namespace" in item)) {

    //lets check if the defination has a namespace
    if (("define" in item)) {
      if ("namespace" in item.define) {
        item.namespace = item.define.namespace;
      }
    }
  
    //if the item still doesn't have a namespace, the item is invalid.
    if (!("namespace" in item)) {

      console.error("The object must register with a namesapce.", {
        item: item,
        stack: GenerateStacktrace()
      });
      log.add("Module: Registry.add.namespace", "The object must register with a namesapce.", item, 3);
      //log.e(this._proc, this._st, "NamespacePropNotFound", `"The object does not have a namespace."`, item);
      return false;

    }
  }

  //force the namespace to be normalized
  item.namespace = NormalizeNamespace(item.namespace);


  if (this.registry.length < 1) {
    //I have no items in my registry, so I'm safe.
  } else {
    if (this.search(item.namespace,false) !== false) {
      console.error("The registry already has an object registered by the namesapce", item.namespace, {
        item: item,
        stack: GenerateStacktrace()
      });
      log.add("Module: Registry.add.namespace.duplicate", `The registry already has an object registered by the namesapce ${item.namespace}.`, item, 3);
      return false;
    }
  }

  if ("define" in item) {
    if (!(from)) {
      item.define.from = GenerateStacktrace(1);
    } else {
      item.define.from = from;
    }
  }

  //add outside of for so this new element isn't counted
  this.registry.push(item);
  
  if (this.events.onAdd.length > 0) {
    for (var i = 0; i < this.events.onAdd.length; i++) {
      var event = this.events.onAdd[i];
      var repo = event(this, item);
      if (!repo.success) {
        log.add("Module: Registry.add.event.onadd", "The object failed the onAdd event", {
          repo: repo,
          event: event,
          item: item
        }, 3);
        //log.e(this._proc, this._st, "onAdd", `"The object falied the onAdd event."`, repo, event, item);
      }
    }
  }

 return true;
}

module.exports = create();

/**
 * Registers objects into the registry with the proper extension.
 * @param {string} location The folder location of the objects to register.
 * @param {string} type The file extension of the object to add. Defaults to "js"
 */
function addFromDir(location, type = "js") {

  if (type.charAt(0) !== ".") {
    type = `.${type}`;
  } 

  if (!santize("string", location)) {
    log.add("Module: Registry.addFromDir.santize", "The paramater location failed sanitization.", location, 3);
  }

  var _on = "addDromDir";
  
  var fs = require("fs");
  const path = require("path");

  var files = fs.readdirSync(location);
 
  var i = 0;
  while (files.length > i) {
      var cur = files[i];
      var fullpath = location + "/" + cur;
      if (path.extname(cur) == type) {
        this.add(require(fullpath), GenerateStacktrace(1));
      }
      i = i + 1;
  }

}

module.exports = create;
module.exports.log = log;
