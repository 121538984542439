/**
 * (c) 2023. Justin K Kazmierczak. All rights reserved.
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Universe App Tools
 * 
 * Justin K Kazmierczak
 * 
 * Handles all events registered in your application that are not handled by system ready components.
 * 
 * An event(type) should be created by namesapce with the following properties:
 * {
 *  namespace: "namespace",
 *  description: "Description of the event",
 *  params: [
 *  {
 *      name: "param1",
 *      type: "string",
 *      description: "Param 1 description",
 *      required: true
 *  }]
 * }
 * 
 * This enables a developer to add to their list of reports so they can dig deeper into their app and 
 * make it more accessible to other developers.
 */

/**
 * The namespace of the event manager.
 */
var namespace = "ua.events";
var jsonschema = require("./functions/jsonschema.js").function;

var GenerateSafeError  = require("./functions/generateSafeError.js").function;
var fm = require("./filemanager.js");

/**
 * The event listerners to fire on an envent.
 */
var eventListeners = {};

/**
 * The events registered to the event manager.
 */
var events = require("./registry.js")("ua.events");

var isUAT = false;

/**
 * Helps Universe App Tools (client side) use this module.
 * @param {*} _isUAT 
 */
function init(_isUAT) {
    isUAT = _isUAT;
} module.exports.init = init;

/**
 * Adds an event listener to the event manager.
 * @param {*} eventType The namespace of the event to listen for.
 * @param {*} callback The callback function to run when the event is fired.
 * @param {*} scope The scope of the callback function.
 */
function on(eventType, callback, scope = null) {

  // console.log(`Adding event listener for ${eventType}.`, {
  //   stacktrace: GenerateStacktrace(1),
  //   eventListeners: report()
  // });

  if (!eventListeners[eventType]) {
    // console.log(`Event listener added for ${eventType}.`, report());
    eventListeners[eventType] = [];
  }

  var from = GenerateStacktrace(1)[0];

    // if (eventListeners[eventType].length == 0) {
    //   console.log(`Event listener added for ${eventType}.`, report());
    //   eventListeners[eventType] = [];
    // }
    eventListeners[eventType].push({ callback, scope, from });

    // console.log(`Event listener added for ${eventType}. Count: ${eventListeners[eventType].length}`, report());

} module.exports.on = on;

function report() {
  //reports the number of events added by listeners
  var rtn = {};
  for (var i = 0; i < events.length(); i++) {
    var event = events.registry[i];
    rtn[event.namespace] = eventListeners[event.namespace] ? eventListeners[event.namespace].length : 0;
  }
  return rtn;
}

// /**
//  * Removes an event listener from the event manager.
//  * @param {*} eventType The namespace of the event to remove.
//  * @param {*} callback The callback function to remove.
//  */
// function removeEventListener(eventType, callback) {
//     if (eventListeners[eventType]) {
//         eventListeners[eventType] = eventListeners[eventType].filter(
//             listener => listener.callback !== callback
//         );
//     }
// } module.exports.remove = removeEventListener;

// /**
//  * Removes an event from the event manager.
//  * @param {*} eventNamespace The namespace of the event to remove.
//  */
// function removeEvent(eventNamespace) {
//   delete eventListeners[eventNamespace];

//   events.

//   // if (eventListeners[eventNamespace]) {
//     //     delete eventListeners[eventNamespace];
        
//     //     //remove the event from the events list
//     //     events = events.filter(event => event.namespace !== eventNamespace);

//     // }
// } module.exports.unregister = removeEvent;

/**
 * Runs the event and fires the listeners for the event type, in the order they were added.
 * This function awaits the completion of a listerner before moving to the next listener.
 * To fire listernes in parallel, use the runNoAwait function.
 * @param {*} event The namespace of the event.
 * @param {*} data The data (if any) to pass to the event.
 * @alias fire Sometimes it's better to fire an event than to run it.
 */
async function run(event, ...data) {

  var rtn = false;
  
    if (eventListeners[event]) {

      // if (!isUAT) console.log(`Firering event: ${event} count: ${eventListeners[event].length}`)
      // console.log(`Firering event: ${event} count: ${eventListeners[event].length}`)

      var eventDefinition = null;

      try {
        eventDefinition = events.find(event);
      } catch (error) {
        
      }

      rtn = [];

      for (var i = 0; i < eventListeners[event].length; i++) {
       
        var listener = eventListeners[event][i];

        try {
          var callback = listener.scope ? listener.callback.bind(listener.scope) : listener.callback;
          rtn.push(await callback(...data));
        } catch (error) {
          // error.data = {
          //   listener
          // };

          console.error(`Event: ${event} caused an error. ${error.toString()}`, {
            eventDefinition: eventDefinition,
            event: listener,
            error: GenerateSafeError(error)
          });
        }


      }

    // await eventListeners[event].forEach(async listener => {
    //     const callback = listener.scope ? listener.callback.bind(listener.scope) : listener.callback;
    //     await callback(data);
    // });
    } else {
      // if (!isUAT) console.info(`No listeners for event: ${event} at fire time.`);
    }

    return rtn;

} module.exports.run = run;
module.exports.fire = run;

/**
 * Runs the event and fires the listeners for the event type, in the order they were added.
 * This event does not await the completion of a listener before moving to the next listener.
 * @param {*} event The namespace of the event.
 * @param  {...any} data The data (if any) to pass to the event.
 */
function runNoAwait(event, ...data) {

  if (eventListeners[event]) {

      // if (!isUAT) console.log(`Firering event: ${event} count: ${eventListeners[event].length}`)

      for (var i = 0; i < eventListeners[event].length; i++) {
      var listener = eventListeners[event][i];
      var callback = listener.scope ? listener.callback.bind(listener.scope) : listener.callback;
      callback(...data);
    }

  } else {

    // console.info(`No listeners for event: ${event} at fire time.`);

  }

} module.exports.runNoAwait = runNoAwait;

/**
 * Counts the number of listeners for an event.
 * @param {string} event The namespace of the event.
 * @returns {number} The number of listeners for the event.
 */
function count(event) {
    if (eventListeners[event]) {
        return eventListeners[event].length;
    } else {
        return 0;
    }
}
module.exports.count = count;

/**
 * Clears all listeners for an event type.
 * @param {*} event The namespace of the event.
 */
function ClearListeners(event) {
  if (eventListeners[event]) {
      eventListeners[event] = [];
  }
} module.exports.clear = ClearListeners;

/**
 * Saves the events to a file.
 * @returns {boolean} True if the events were saved.
 */
function Save() {

  if (isUAT) {
    return;
  }

  //does the file exist?
  if (fm.exists(`events.json`)) {

    //load the events from the file
    var evntz = fm.loadJSON(`events`);

    //merge the events with the new events
    for (var i = 0; i < events.length(); i++) {
      var event = events.registry[i];
      if (!evntz[event.namespace]) {
        evntz[event.namespace] = event;
      }
    }

    return fm.SaveLogJSON(`events`, evntz);

  } else {
    var eventz = {};
    for (var i = 0; i < events.length(); i++) {
      var event = events.registry[i];
      eventz[event.namespace] = event;
    }

    return fm.SaveLogJSON(`events`, eventz);
  }

} module.exports.save = Save;

var GenerateStacktrace = require("./functions/generateStacktrace.js").function;

/**
 * Registers an event type with the event manager.
 * @param {*} eve The event object, or arry of event objects to register.
 */
function register(eve) {
 
  //if the eve is an array
  if (Array.isArray(eve)) {
    for (var i = 0; i < eve.length; i++) {
      register(eve[i]);
    }
  } else if (typeof eve === "object") {
    RegisterEvent(eve);
  } else {
    throw new Error("Invalid event type.");
  }

} module.exports.register = register;

/**
 * Registers an event type with the event manager.
 * @param {*} eve The event object to register.
 */
function RegisterEvent(eve) {
  //validate the event type
  jsonschema($jsonSchema, eve);

  //add a from line
  // eve.from = GenerateStacktrace(3)[0];
  // console.log("Registering event", {
  //   from: eve.from,
  //   event: eve
  // });

  var from = GenerateStacktrace(3)[0];
  eve.from = from;

  if (!events.add(eve, from)) {
    console.log(`Event type ${eve.namespace} already registered or could not be registered.`, {
      event: eve,
      from: from
    });
    throw new Error(`Event type ${eve.namespace} already registered or could not be registered.`);
  }

  if (!eventListeners[eve.namespace]) {
    eventListeners[eve.namespace] = [];
  }

  // eventListeners[eve.namespace] = [];

  // console.log(`Event type ${eve.namespace} registered.`, {
  //   event: eve,
  //   from: from
  // });

  Save();

  // //check if the event type is already registered
  // if (!events.find(eve.namespace)) {
    
  //     eventListeners[eve.namespace] = [];
  //     events.push(eve);

  //     //stores the changes
  //     Save();

  // } else {
  //     throw new Error(`Event type ${eve.namespace} already registered.`);
  // }
}

//example event type
var $jsonSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "type": "object",
    "properties": {
      "namespace": {
        "type": "string",
        "description": "The event name",
        "minLength": 1
      },
      "description": {
        "type": "string",
        "description": "Description of the event",
        "minLength": 1
      },
      "params": {
        "type": "array",
        "description": "List of parameters for the event",
        "items": {
          "type": "object",
          "properties": {
            "name": {
              "type": "string",
              "description": "Parameter name",
              "minLength": 1
            },
            "type": {
              "type": "string",
              "description": "Parameter data type"
            },
            "description": {
              "type": "string",
              "description": "Parameter description",
              "minLength": 1
            },
            "required": {
              "type": "boolean",
              "description": "Is the parameter required?"
            }, "default": {
              "type": "string",
              "description": "The default value of the parameter, if not required."
            }
          },
          "required": ["name", "type", "description"]
        }
      }
    },
    "required": ["namespace", "description"]
  };  

module.exports.tests = [{
    namespace: `${namespace}.simpleScopeTest`,
    must: 25,
    run: async () => {
        //register new event "test"

        var events = require("../uam/events.js");

        events.register({
            namespace: `${namespace}.simpleScopeTest`,
            description: "Simple scope test"
        });

        myscope = 10;

        events.on(`${namespace}.simpleScopeTest`, function () {
            // console.log("simpleScopeTest obj", myscope);
            // console.log("simpleScopeTest this.obj", this.myscope);
            myscope = myscope + 3;

            // console.log("simpleScopeTest obj", myscope);
            // console.log("simpleScopeTest this.obj", this.myscope);
        });

        myscope = 22;

        await events.run(`${namespace}.simpleScopeTest`);

        //remove the event
        events.unregister(`${namespace}.simpleScopeTest`);

        return myscope;
    }
}, {
  namespace: `${namespace}.passingAndReturningData`,
  must: 25,
  run: async () => {
      //register new event "test"

      var events = require("../uam/events.js");

      events.register({
          namespace: `${namespace}.passingAndReturningData`,
          description: "Passing and returning data"
      });

      events.on(`${namespace}.passingAndReturningData`, async function (data) {
          // console.log("main", data);
          return data + 5;
      });

      var rtn = await events.run(`${namespace}.passingAndReturningData`, 20);
      // console.log("passingAndReturningData", rtn);

      return rtn[0];
  }
}]