/**
 * 
 * Universe App Tools
 * Adds a fullscreen closeable module to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

var namespace = "ua.fs.module";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");
var uai = require("../interface/interface.js");

var required = [];
exports.required = required;

exports.define = {
  namespace: namespace,
  fields: {
    "type": {
      type: "string",
      description: "The type of fs fixed background to use. Defaults to image."
    }, "src": {
      type: "string",
      description: "The source of the image or video."
    // }, "inner": {
    //   type: "inner",
    //   description: "The inner items to display on top of the background."
    }, "poster": {
      type: "uri",
      description: "The poster image to display before the video plays."
    }
  },
  required: required
} 



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
  * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (json) {

  var options = uae.GetOptions(json, required);

    var bg = {
      n: "video",
      c: "ua-fs-background ua-fs-background-video",
      playsinline: "",
      muted: "",
      loop: "",
      i: {
        n: "source",
        src: options.src,
        type: "video/mp4"
      }
    }

    var playButton = {
      n: "button",
      c: "ua-fs-background-play"
    };

    var article = {
      n: "article",
      c: "ua-fs-background-video-holster",
      i: [bg]
    };
  
    
    if ("inner" in options) {
      article.i.push(options.inner);
    }
  
    if ("poster" in options) {
      bg.poster = options.poster;
    }
  
    if ("fixed" in options) {
      if (!(options.fixed)) {
        //its false
      } else {
        bg.c += " video-fixed";
        bg.fixed = options.fixed;
        // article.fixed = options.fixed;
      }
    }

  return await jsonRender.render(article);
  
};