/* {"ns":"universe.functions.resolve", "v":1, "i": false} */

// const { resolveInclude } = require("ejs");

/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "describeAccountingDate";

// var jckConsole = require("@jumpcutking/console");

/**
 * Converts a 8 digit year to a date
 * @param {*} date The 8 digit year.
 * @returns A human readable date of the form: Month Day, Year
 */
function DescribeAccountingDate(date) {

    //can i convert the date to a number, If i can't then it's a string
    var dateNumber = Number(date);

    if (isNaN(dateNumber)) {
        // convert to a date object
        date = new Date(date);
        // return date;
    }

    //if it's a Date, convert it to a string
    if (date instanceof Date) {
        return date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " + date.getFullYear();
    }

    date = dateNumber.toString();

 
    //is it a number?
    // if (typeof date == "number") {
        // var date = options.date;
        var year = date.substring(0, 4);
        var month = date.substring(4, 6);
        var day = date.substring(6, 8);
    
        date = new Date(`${year}-${month}-${day}`);
        // console.info(`Result: ${date.toLocaleString('default', { month: 'long' }) + " " + day + ", " + year}`);
        return date.toLocaleString('default', { month: 'long' }) + " " + day + ", " + year;
    // }

    //is it a string?
    if (typeof date == "string") {  
        return date;
    }

    
  
}

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: DescribeAccountingDate,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
            
            var date = "20110419";
            var result = DescribeAccountingDate(date);
            
            if (result == "April 19, 2011") {
                return true;
            } else {
                return false;
            }
        }
    }]
};